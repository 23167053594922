const ManageStampInfoDto = {
	address: "",
	content: "",
	copiesNumber: 0,
	createdDate: null,
	createdName: "",
	createdOrgName: "",
	createdRankName: "",
	creater: null,
	email: "",
	expiredDateReturn: null,
	id: null,
	isActive: true,
	isDelete: true,
	modifiedDate: null,
	modifiedName: "",
	modifier: null,
	name: "",
	orgReturn: null,
	orgReturnName: "",
	orgStorages: [],
	phoneNumber: "",
	requestData: null,
	returnDate: null,
	stamp: null,
	stampCode: "",
	stampDate: null,
	stampName: "",
	stamperName: "",
	status: "",
	storageCode: "",
	storageLocation: "",
	storagePosition: "",
	submitSignDate: null,
	receiveDate: null,
	expiredDateStorage: null,
};
export default ManageStampInfoDto;
