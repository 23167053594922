const FieldData = {
	col: null,
	created: null,
	createdDate: new Date(),
	createdName: "",
	createdOrgName: "",
	createdRankName: "",
	description: "",
	field: null,
	fieldDataCode: "",
	fieldDataName: "",
	fieldInForm: null,
	fieldPattern: "",
	formData: null,
	id: null,
	isActive: true,
	modified: null,
	modifiedDate: new Date(),
	modifiedName: "",
	nameOfField: "",
	objectModel: null,
	objectSchema: "",
	option: "",
	order: null,
	requestData: null,
	required: "",
	row: null,
	tennant: null,
	tennantCode: "",
	tennantName: "",
};
export default FieldData;
