const RequestDataDto = {
	id: null,
	requestDataCode: null,
	requestDataName: null,
	directoryPath: "",
	description: "",
	statusName: "",
	oldStatus: null,
	objectSchema: "",
	objectModel: "",
	expiredTime: new Date(),
	createdName: "",
	createdDate: new Date(),
	modifiedName: "",
	modifiedDate: new Date(),
	isActive: true,
	isDelete: false,
	tennantCode: "",
	tennantName: "",
	request: null,
	status: null,
	tennant: null,
	created: null,
	modified: null,
	userInfos: null,
	requestDataList: [],
	currentRound: null,
	requestGroupName: "",
	requestTypeName: "",
	plotOfLandNumber: null,
	title: "",
	sapMapping: "",
	dataRoomDriveId: "",
	dataRoomId: "",
	dataRoomPath: "",
	ruleGenerateAttachName: "",
};
export default RequestDataDto;
