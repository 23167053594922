const ReqDataProcessHisDto = {
	isChild: false,
	createDate: new Date(),
	description: "",
	id: null,
	processDate: new Date(),
	processer: null,
	processerName: "",
	rankName: "",
	requestData: null,
	isShowCustomer: false,
	status: "",
	stepData: null,
	tennantCode: "",
	tennantId: 0,
	tennantName: "",
	organizationName: "",
};
export default ReqDataProcessHisDto;
