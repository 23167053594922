// const StepDataDto = {
// 	id: null,
// 	stepDataCode: "",
// 	stepDataName: "",
// 	timeActive: new Date(),
// 	processingTerm: null,
// 	precessingTermTime: new Date(),
// 	isRequiredSignature: null,
// 	isBack: null,
// 	isAuthorizedApproval: null,
// 	isExamine: null,
// 	isEdit: null,
// 	isRequestModify: null,
// 	isSendMail: null,
// 	isProcessRecover: null,
// 	isCreateTfsTask: null,
// 	organizationCode: "",
// 	organizationName: "",
// 	rankCode: "",
// 	rankName: "",
// 	description: "",
// 	createdName: "",
// 	createdDate: new Date(),
// 	modifiedName: "",
// 	modifiedDate: new Date(),
// 	isActive: false,
// 	isDelete: true,
// 	tennantCode: "",
// 	tennantName: "",
// 	nextStep: null,
// 	processData: null,
// 	requestData: null,
// 	tennant: null,
// 	created: null,
// 	modified: null,
// 	stepInProcess: null,
// 	stepOrder: "",
// 	userInfos: [],
// 	optionDeny:null,
// };
// export default StepDataDto;

const StepDataDto = {
	contractType: "",
	createContractType: "",
	created: null,
	createdDate: new Date(),
	createdName: "",
	createdOrgName: "",
	createdRankName: "",
	description: "",
	id: null,
	isActive: false,
	isAddHistory: false,
	isAddQRCode: false,
	isAddWaterMark: false,
	isApprove: true,
	isAttachFilePDF: false,
	isAuthorizedApproval: false,
	isAutoSendMail: false,
	isBack: false,
	isChangeStatus: false,
	isCreateContract: false,
	isCreateOTPLink: false,
	isCreateOfficalDispath: false,
	isCreateReport: false,
	isCreateTFSTask: false,
	isDeny: true,
	isEdit: false,
	isExamine: false,
	isExportPDF: false,
	isManageStamp: false,
	isProcessRecover: false,
	isRecall: false,
	isRequestModify: false,
	isRequiredSignature: false,
	isSaveDoc: false,
	isSendMail: false,
	isSendNoticePriority: false,
	mailTemplate: null,
	modified: null,
	modifiedDate: new Date(),
	modifiedName: "",
	optionDeny: 0,
	organizationCode: "",
	organizationName: "",
	processData: null,
	processOfContract: "string",
	processingTerm: 0,
	processingTermTime: new Date(),
	rank: null,
	rankCode: "",
	rankName: "",
	requestData: { id: null },
	roundNumber: null,
	sendNoticePriorityType: "",
	stepDataCode: "",
	stepDataName: "",
	stepInProcess: null,
	stepOrder: null,
	tennant: null,
	tennantCode: "",
	tennantName: "",
	timeActive: new Date(),
	userInfos: [],
	isConsult: true,
	isProcessSaveDoc: false,
	isSendMailCustomer: false,
	mailTemplateCustomer: null,
};

export default StepDataDto;
